<template>
    <div  class="labelSlide">
        <van-tabs class="uls"  v-model="moveIndex" swipeable title-active-color="#333333" title-color="#666" color="#1472FF" line-width="0.48rem" line-height="0.04rem">  
            <van-tab v-for="(item,index) in labelObj.list" :key="item.id"
            :title="item.tag_name" >
                <!-- <div class="defaultLi">
                    {{item}}
                    <span></span>
                </div> -->
            </van-tab>      
        <!-- <ul>
            <li v-for="(item,index) in labelObj.list" :key="index" 
            :class="index == moveIndex?'active':''"
            @click="move(index)">
                {{item}}
                <span></span>
            </li>
        </ul> -->
        </van-tabs>
    </div>
</template>
<script>
export default {
    name:'labelSlide',
    props:{
        labelObj:{
            type:Object,
            default:{}
        }

    },
    
    watch:{
        labelObj(val){
            if(val&&val.list){
                this.moveIndex = val.activeIndex;
            }
        }
    },
    data(){
        return{
            moveIndex:0,
        }
    },
    methods:{
        move(index){
            this.moveIndex = index;
        }
    }
    
}
</script>
<style lang="scss" scoped>
.labelSlide{
    width:100%;
    .uls{
        // width:100%;
        // overflow-x:auto;
        // white-space: nowrap;
        // padding:.24rem 0;
        .defaultLi{
            // padding:.24rem 0 .24rem 0;
            // display: inline-block;
            // font-size:.28rem;
            // color:#666666;
            // margin:0 .24rem;
            // &:first-child{
            //     margin-left:0; 
            // }
        }
        .active{
            position: relative;
            font-weight: 600;
            color:#333;
            span{
                width:.48rem;
                height:.04rem;
                background: $colorActive;
                position: absolute;
                bottom:.12rem;
                left:0;
                right:0;
                margin: 0 auto;
                // left:calc(100% - .48rem)/2;
                transition: all .3s;
                border-radius: 1px;
            }
        }
    }
    /deep/{
        .van-tabs__nav--complete{
            padding-left:0;
            padding-right:0;
        }
        .van-tabs__wrap--scrollable .van-tab{
            &:first-child{
                margin-left:-.24rem;
            }
        }
        .van-tab--active{
            font-weight: 600;
        }
        .van-tabs__nav--line{
            padding-bottom:.16rem;

        }
    } 
}
    
</style>