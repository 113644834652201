<template>
    <div class="modal" @click.stop="cancleModel">
        <div class="content"  @click.stop="defaultClick">
            <div class="screenList" v-show="status == 1">
                <div class="screenItem">
                    <div class="title">主营类目<span>（最多选择5个）</span></div>
                    <ul class="list">
                        <li :class="isExit('cateArray',item)?'active':''" @click="getActive('cateArray',item)" v-for="item in categories.industries" :key="item.id">{{item.tag_name}}</li>
                    </ul>
                </div>
                <!-- <div class="screenItem">
                    <div class="title">店铺活跃度</div>
                    <ul class="list clearfix">
                        <li>五星</li>
                        <li>四星</li>
                        <li>三星</li>
                        <li>二星</li>
                        <li>一星</li>
                    </ul>
                </div>
                <div class="screenItem">
                    <div class="title">商家规模</div>
                    <ul class="list clearfix">
                        <li>1~10人</li>
                        <li>11~50人</li>
                        <li>50~300人</li>
                        <li>300以上</li>
                    </ul>
                </div>
                <div class="screenItem">
                    <div class="title">店铺流水</div>
                    <div class="inputBorder">
                        <div class="left">
                            <input type="number" placeholder="最少">
                        </div>
                        <div class="center">-</div>
                        <div class="left">
                            <input type="number" placeholder="最多">
                        </div>
                    </div>
                </div>
                 <div class="screenItem">
                    <div class="title">店铺用户数</div>
                    <div class="inputBorder">
                        <div class="left">
                            <input type="number" placeholder="最少">
                        </div>
                        <div class="center">-</div>
                        <div class="left">
                            <input type="number" placeholder="最多">
                        </div>
                    </div>
                </div> -->
                <div class="screenItem">
                    <div class="title">所在省市</div>
                    <ul class="list clearfix">
                        <li  v-for="ite in categories.cities" :key="ite.label" 
                         :class="isExit('cityArray',ite)?'active':''" 
                         @click="getActive('cityArray',ite)" 
                        >{{ite.label}}</li>
                    </ul>
                </div>
                <!-- <div class="screenItem">
                    <div class="title">版本类型</div>
                    <ul class="list clearfix">
                        <li>标准版</li>
                        <li>专业版</li>
                        <li>旗舰版</li>
                    </ul>
                </div> -->
            </div>
            <div class="categoryList" v-show="status == 0">
                <div class="selected">
                    已选类目：
                    <span></span>
                    <span>等5个</span>
                </div>
                <div class="category">
                    <mt-checklist
                    v-model="value"
                    @change="changeValue"
                    :options=list.arr>
                    </mt-checklist>
                </div>

            </div>
           
        </div>
        <div class="btns">
            <p class="cancle" @click="reset">重置</p>
            <p class="confirm" @click="confrim">确认</p>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Checklist  } from 'mint-ui';
Vue.component(Checklist .name, Checklist);
export default {
    name:'screenModal',
    props:{
        categories:{
            type:Object
        }
    },
    computed:{
        
    },
    data(){
        return{
            status:1,
            list:{
                arr:['全部','职场技能','医疗行业'],
            },
            value:['全部'],
            // isExit:false,
            userCate:{
                cateArray:[],
                cityArray:[]
            }
            
            
        }
    },
    methods:{
        //重置
        reset(){
            this.userCate = {
                cateArray:[],
                cityArray:[]
            }
        },
        isExit(type,item){
            let id = type=='cateArray'?item.id:item.value;
            return this.userCate[type].indexOf(id)>-1;
        },
        getActive(type,ele){
            let cateArray = this.userCate[type];
            let id = type == 'cateArray'?ele.id:ele.value;
            console.log('cateArray',cateArray)
            if(cateArray.indexOf(id) == -1){
                if(cateArray.length>=5){
                    this.$Toast('只能最多选择5个')
                    return
                }
                cateArray.push(id)
            }else{
                for(let i = 0;i<cateArray.length;i++){
                    if(cateArray[i] == id){
                        cateArray.splice(i,1);
                    }
                }
            }
            this.userCate[type] = cateArray;
        },
        confrim(){
            if(this.userCate.cateArray==0&&this.userCate.cityArray == 0){
                this.$Toast('请选择')
                return 
            }
            console.log('userCate',this.userCate)
            this.$emit('confirm',this.userCate)
        },
        changeValue(){
            console.log('this',this.value)

        },
        cancleModel(){
            this.$emit('cancleModel')
        },
        defaultClick(){

        }
    }
}
</script>
<style lang="scss" scoped>
.modal{
    width:100%;
    height:100vh;
    position: fixed;
    top:0;
    left:0;
    z-index:7;
    background: rgba(0,0,0,.5);
    .content{
        width:calc(100% - .86rem);
        margin-left:.86rem;
        // padding:.16rem;
        height: 100vh;
        background: #fff;
        border-radius: 10px 0 0 10px;
        overflow-y:auto;
        .screenList{
            padding:0 .32rem;
            padding-bottom:1.12rem;
            .screenItem{
                .title{
                    font-size:.32rem;
                    font-weight: 600;
                    padding:.32rem 0;
                    color:#333333;
                    font-weight: 500;
                    span{
                        color:#666666;
                        font-weight: normal;
                    }
                }
                .list{
                    li{
                        list-style: none;
                        width: 1.84rem;
                        background: #F5F5F5;
                        margin: 0 0.16rem 0.2rem 0;
                        border-radius: 0.4rem;
                        text-align: center;
                        height: .64rem;
                        font-size: .28rem;
                        display: flex;
                        float: left;
                        justify-content: center;
                        align-items: center;
                        font-size: .24rem;
                        word-wrap: break-word;
                    }
                    .active{
                        color:#1472FF;
                        background: #E7EDFB;
                        border: 1px solid #1472FF;
                        box-sizing: border-box;
                    }
                }
                .clearfix{
                    clear:both;
                    overflow: hidden;
                    content:'.';

                }
                .inputBorder{
                    display: flex;
                    align-items: center;
                    .left{
                        flex:1;
                        input{
                            width:100%;
                            border:1px solid #999;
                            height:26px;
                            text-align: center;
                            border-radius: 20px;
                            &:focus{
                                outline:none
                            }
                        }
                    }
                    .center{
                        width:30px;
                        text-align: center;
                    }

                }

            }

        }
        .categoryList{
            width:100%;
            padding-bottom:50px;
            .selected{
                padding:16px;
                border-bottom:1px solid #ccc;
                font-size:14px;
            }
            .category{
                font-size:14px;

            }


        }
        
    }
    .btns{
        width:88%;
        height:1.12rem;
        position: absolute;
        bottom:0;
        left:12%;
        // border-top:1px solid #ccc;
        background: #fff;
        display: flex;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        p{
            flex:1;
            margin:.16rem .2rem 0;
            height:.8rem;
            // line-height: .8rem;
            text-align: center;
            border-radius: .4rem;
            font-size:.32rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .cancle{
            border:1px solid #ccc;
            color:#333333;
        }
        .confirm{
            background: $colorActive;
            color:#fff;
        }
    }
}
    
</style>