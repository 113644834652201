<template>
    <div class="container">
        <div  class="labelSlide label">
            <van-tabs class="uls"  v-model="activeIndex" swipeable title-active-color="#333333" title-color="#666" color="#1472FF" line-width="0.48rem" line-height="0.04rem">  
                <van-tab v-for="(item,index) in labelObj.list" :key="item.id"
                :title="item.tag_name" >
                </van-tab>      
            </van-tabs>
        </div>
        <!-- <label-slide :labelObj="labelObj" class="label" ></label-slide> -->
        <div class="chooseIcon" @click="screen"><i class="iconfont icon-shaixuanbeifen2"></i></div>
    </div>
</template>
<script>
import labelSlide from './labelSlide'
export default {
    name:'labelList',
    components:{
        labelSlide
    },
    props:{
        industries:{
            type:Array,
        }
    },
    watch:{
        industries(val){
            console.log('val',val);
            if(val&&val.length!=0){
                this.labelObj.list = val;
            }
        },
        activeIndex(val){
            if(val){
                this.$emit('slide',val)
            }
        }
    },
    data(){
        return{
            labelObj:{
                list:[
                    {id:0,tag_name:'全部行业'},
                    {id:1,tag_name:'语言培训'},
                    {id:2,tag_name:'早教幼教'},
                    {id:3,tag_name:'考研留学'},
                    {id:4,tag_name:'艺术教育'},
                    {id:5,tag_name:'职称公考'},
                ],
            },
            activeIndex:0,


        }
    },
    methods:{
        screen(){
            this.$emit('screen')
        }
    }
    
}
</script>
<style lang="scss" scoped>
.container{
    width:$defaultWidth;
    margin:0 auto 0;
    display: flex;
    align-items: center;
    .label{
        flex:1;
        min-width: 0;
    }
    .chooseIcon{
        width:.6rem;
        height:.88rem;
        line-height: .88rem;
        background: #fff;
        box-shadow: -.16rem 0px .16rem -.16rem rgba(0, 0, 0, 0.3);
        color:#000000;
        text-align: right;
        position: relative;
        z-index:3;
        i{
            font-size:.32rem;
        }
    }
    .labelSlide{
    width:100%;
    .uls{
        // width:100%;
        // overflow-x:auto;
        // white-space: nowrap;
        // padding:.24rem 0;
        .defaultLi{
            // padding:.24rem 0 .24rem 0;
            // display: inline-block;
            // font-size:.28rem;
            // color:#666666;
            // margin:0 .24rem;
            // &:first-child{
            //     margin-left:0; 
            // }
        }
        .active{
            position: relative;
            font-weight: 600;
            color:#333;
            span{
                width:.48rem;
                height:.04rem;
                background: $colorActive;
                position: absolute;
                bottom:.12rem;
                left:0;
                right:0;
                margin: 0 auto;
                // left:calc(100% - .48rem)/2;
                transition: all .3s;
                border-radius: 1px;
            }
        }
    }
    /deep/{
        .van-tabs__nav--complete{
            padding-left:0;
            padding-right:0;
        }
        .van-tabs__wrap--scrollable .van-tab{
            &:first-child{
                margin-left:-.24rem;
            }
        }
        .van-tab--active{
            font-weight: 600;
        }
        .van-tabs__nav--line{
            padding-bottom:.16rem;

        }
    } 
}

}
    
</style>