<template>
    <div class="caseList">
        <div class="caseWrap" @click="goToDetail(item)" v-for="item in all_cases" :key="item.app_id">
            <div class="caseBox">
                <div class="boxLeft"><img :src="item.shop_logo?item.shop_logo:defaultImg" alt=""></div>
                <div class="boxRight">
                    <div class="title">{{item.shop_name || '--'}}</div>
                    <div class="desc" v-if="terminal == 1">{{item.introduction}}</div>
                    <template v-if="terminal == 1">
                        <div class="label" v-if="item.industry_type">
                            <p v-for="(d,ind) in item.industry_type.split(',')" :key="ind">{{d}}</p>
                        </div>
                        <div class="footer">
                            <div class="address"><i class="iconfont icon-gengduobeifen23"></i>{{item.city}}</div>
                            <div class="forward" v-if="page !='detail'"><i class="iconfont icon-fenxiangbeifen"  @click.stop="share(item)"></i></div>
                            <!-- <div class="handle">
                                分享0<span>·</span>查看0<span>·</span>收藏0
                            </div> -->
                        </div>
                    </template>
                </div>
            </div>
            <div class="other" v-if="page =='detail'">
                <p class="h5" v-if="item.h5" @click="visitWeb(item)">访问H5店铺</p>
                <div class="h5" v-if="item.micro_program" @click="visitMinPro(item)">访问小程序店铺</div>
            </div>
            <div class="other" v-if="terminal == 1">
                <!-- <div class="otherLeft">
                    <div class="address"><i class="iconfont icon-gengduobeifen23"></i>浙江省</div>
                </div>
                <div class="otherRight">
                    <div class="label">
                        <p>财经楼市</p>
                        <p>五星</p>
                        <p>1~10人</p>
                        <p>旗舰版</p>
                        <p>H5</p>
                        <p>小程序</p>
                    </div>
                    <div class="bths">
                        <p class="h5">访问H5店铺</p>
                        <div class="h5">访问小程序店铺</div>
                    </div>
                </div> -->
            </div>
        </div>
       
    </div>
</template>
<script>
import { setToken , getToken } from '@/util/util'
export default {
    name:'caseList',
    data(){
        return{
            terminal:1,
            defaultImg:'http://wechatapppro-1252524126.file.myqcloud.com/apprnDA0ZDw4581/image/0398fe634cc41cd9fa619772271b7c11.png',
            listData:[],
        }
    },
    props:{
        all_cases:{
            type:Array,
        },
        page:{
            type:String,
        }
    },
    watch:{
        
    },
    mounted(){
        
        console.log('all_cases',this.all_cases)

    },
    methods:{
        visitWeb(item){ //h5点击外部链接
            window.location.href = item.h5_url;
        },
        visitMinPro(item){
            if(item.micro_program_isFormal == 0){
                window.location.href = item.micro_program_url;
            }else if(item.micro_program_isFormal == 1){
                this.$emit('postMicro')
            }
        },
        share(item){
            let origin = window.origin;
            let obj = {
                title:item.shop_name?item.shop_name:'案例标题',
                desc:'点击查看案例详情',
                link:origin+'/subapp/sub_users/detail?appid='+item.app_id,
                imgUrl:item.shop_logo?item.shop_logo:this.defaultImg,
                success:function(){
                    // alert('成功')
                   
                }
            }
            // this.$EventBus.$emit('shareContent',true,obj);
            // this.$emit('share');
           
        },
        goToDetail(item){
            if(this.$route.path == '/case'){
                this.$router.push({
                    path:'/detail?appid='+item.app_id
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.caseList{
    width:$defaultWidth;
    margin:.32rem auto 0;
    position: relative;
    z-index: 2;
    .caseWrap{
        border-radius:5px;
        // border:1px solid #999;
        background: #fff;
        padding:.24rem;
        margin:0 0 .24rem 0;
        box-shadow: 0px .04rem .4rem 0px rgba(0, 0, 0, 0.08);
    }
    .caseBox{
        display: flex;
        .boxLeft{
            width:1.6rem;
            // background: red;
            img{
                width:100%;
            }
        }
        .boxRight{
            flex:1;
            margin-left:.24rem;
            position: relative;
            min-width: 0; 
            .title{
                width:calc(100% - .4rem);
                @include ellipsis_1();
                font-size:.32rem;
                color:#333333;
                font-weight: 500;
                
            }
            .desc{
                color:#666;
                font-size:.24rem;
                line-height:.32rem;
                @include ellipsis_line(3);
                margin-top:.2rem;
            }
            .footer{
                display: flex;
                margin-top:.32rem;
                font-size:.2rem;
                .address{
                    width:70%;
                    color:#999999;
                    i{
                        font-size:.24rem;
                    }
                }
                .handle{
                    flex:1;
                    position: relative;
                    text-align: right;
                    color:#999999;
                    font-size:.2rem;
                    span{
                        padding:0 .1rem;
                    }
                }
                .forward{
                    flex:1;
                    text-align: right;
                    i{
                        font-size:.32rem;
                    }
                }
                
            }
            // .forward{
            //     position: absolute;
            //     top:0;
            //     right:0;
            //     i{
            //         font-size:.32rem;
            //     }
            // }
        }
    }
    .label{
        margin-top:.24rem;
        p{
            display: inline-block;
            padding:2px;
            font-size:.24rem;
            color:#666666;
            margin:0 .16rem .08rem 0;
            background: #F5F5F5;
            border-radius: 2px;
            padding:.02rem .16rem;
            
        }
    }
    .other{
        display: flex;
        // margin-top:.24rem;
        .otherLeft{
            width:1.6rem;
            .address{
                color:#999999;
                font-size:.2rem;
                i{
                    font-size:.24rem;
                }
            }
        }
        .otherRight{
            flex:1;
            margin-left:.24rem;
            .label{
                margin-top:0;
            }
            
        }
        .h5{
            font-size: .24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            background: #FFFFFF;
            border-radius: .36rem;
            border: 1px solid #CCCCCC;
            // display: inline-block;
            padding:.18rem .32rem ;
            text-align: center;
            flex:1;
            margin:.32rem 0 0 0;
            &:nth-child(2){
                margin: .32rem 0 0 .3rem;
            }
        }
    }
}

    
</style>