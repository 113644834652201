<template>
    <div class="case">
        <!-- 销售端 -->
        <!-- <div class="search" @click="turn">
            <i class="iconfont icon-gengduobeifen23"></i>
            商家名称
        </div> -->
        <!-- 用户端 -->
        <div class="colorful">
            <div class="bg"><img src="" alt=""></div>
            <div class="header">
                <div class="title">来小鹅通开店</div>
                <div class="subTitle">一分钟拥有你的知识服务平台</div>
                <div class="create">立即创建体验</div>
            </div>
            <div style="clear:both"></div>


        </div>
        <div class="recommendList">
            <div class="title">最新案例</div>
            <ul>
                <li v-for="item in latest_cases" :key="item.app_id" @click="goToDetail(item)">
                    <img :src="item.cover_url" alt="">
                    <div class="caseTitle minHeight">{{item.case_name}}</div>
                    <div class="label">
                        <p>{{item.industry_type}}</p>
                    </div>
                    <!-- <div class="caseTitle turnRight">
                        <span class="opera">13分享</span>
                        <span class="opera">26查看</span>
                        <span class="opera">1收藏</span>
                    </div> -->
                </li>
            </ul>

        </div>
        <div class="fill"></div>
        <label-list @slide="slide" v-show="industries.length!=0" @screen="screenModelFlag = true" :industries="industries"></label-list>
        <case-list :all_cases="all_cases" page="case" @share="sharebg"></case-list>
        <screen-modal  @confirm="confirm" v-show="screenModelFlag" @cancleModel="screenModelFlag=false" :categories="categories"></screen-modal>
        <model :showModel.sync="showModel"></model>

       
    </div>
</template>
<script>
import labelList from '../components/labelList'
import caseList from '../components/caseList'
import screenModal from '../components/screenModal'
import model from '@/components/model'
export default {
    name:'case',
    components:{
        labelList,caseList,screenModal,model
    },
    data(){
        return{
            screenModelFlag:false,
            latest_cases:null,
            industries:[],
            all_cases:[],
            showModel:false,
            categories:{},
            screen:{
                cateArray:[],
                cityArray:[],
                page_index:1,
                page_size:10,
                industry:'',
            }

        }
    },
    watch:{
        showModel(val){
            if(!val){
                // this.$EventBus.$emit('shareContent',false,'/case');
            }
        }
    },
    created(){
        this.initData();
    },
    mounted(){


        

    },
    methods:{
        slide(index){
            console.log('index',index);
            this.screen.industry = this.industries[index].id
        },
        confirm(obj){
            this.screen.cateArray = obj.cateArray;
            this.screen.cityArray = obj.cityArray;
            this.requestCase();
        },
        requestCase(){
            let params = this.screen
            this.$http.get('/user_service/case_library/get_all_case_list',{params}).then(res=>{
                if(res.code == 0){
                    this.all_cases = res.data.items;
                }
            })
        },
        sharebg(){
            this.showModel = true;
        },
        goToDetail(item){
            this.$router.push({
                path:'/detail?appid='+item.app_id
            })
        },
        initData(){
            let params= {
                channel:122333,
            }
            Promise.all([
                this.$http.get('/user_service/case_library/index',{params}),
                this.$http.get('/user_service/case_library/get_search_menu'),
               ])
            .then(res=>{
                console.log('res',res)
                if(res[0].code == 0){
                    this.latest_cases = res[0].data.latest_cases.items;
                    this.industries = res[0].data.industries;
                    this.all_cases = res[0].data.all_cases.items;
                    this.categories = res[1].data;
                }
            })

        },
        turn(){
            this.$router.push({
                path:'/search'
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.case{
    width:100%;
    font-size:14px;
    padding-bottom:30px;
    margin-bottom:$tabHeight;
    .search{
        width:calc(100% - .64rem);
        // width:$defaultWidth;
        margin:.16rem 0 .16rem .4rem;
        // border:1px solid #9a9a9a;
        border-radius: .4rem;
        line-height: .8rem;
        text-indent:.2rem;
        height:.8rem;
        color:#999999;
        background: #F5F5F5;
        i{
            color:#333333;
            font-size:.34rem;
        }
    }
    .colorful{
        width:100%;
        position: relative;
        background: #F5F5F5;
        padding-top:1px;
        padding-bottom:.4rem;
        .bg{
            width:100%;
            height: 2.56rem;
            position: absolute;
            top:0;
            left:0;
            z-index:0;
            background: linear-gradient(134deg, #82CDFF 0%, #AD66FF 100%);
        }
       .header{
           width:$defaultWidth;
           margin:.32rem auto 0;
           background: red;
           border-radius: .16rem;
           text-align: right;
           overflow: hidden;
           position: relative;
           z-index: 2;
           .title{
                font-size: .36rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                padding:.4rem 0 .08rem;
                margin-right:.32rem;
           }
           .subTitle{
                font-size: .24rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                margin-right:.32rem;
           }
           .create{
                width: 2.64rem;
                height: .64rem;
                background: #1472FF;
                border-radius: .4rem;
                opacity: 0.7;
                display: flex;
                align-items: center;
                justify-content: center;
                float: right;
                color: #FFFFFF;
                font-size:.28rem;
                margin:.72rem .32rem .32rem  0;
                // padding:.18rem 0 .18rem;
           }
           


        }
    }
    .recommendList{
        width:100%;
        .title{
            font-size:.32rem;
            font-weight: 500;
            margin:.32rem 0 .24rem $defaultSpacing;
            color:#333333;
        }
        ul{
            width:100%;
            overflow-x:auto;
            white-space: nowrap;
            padding-bottom:.48rem;
            li{
                margin-left:.24rem;
                list-style: none;
                border-radius: .16rem;
                width:3.2rem;
                display: inline-block;
                overflow: hidden;
                white-space: normal;
                box-shadow: 0px .04rem .4rem 0px rgba(0, 0, 0, 0.08);
                img{
                    display: block;
                    width:100%;
                    height: 160px;
                    background-color: red;
                    margin-bottom:.24rem;
                }
                .caseTitle{
                    padding:0 .16rem;
                    margin-bottom:.24rem;
                    color:#666666;
                
                    @include ellipsis_line(2);
                    .opera{
                        color:#999;
                        font-size:.2rem;
                        margin-left:.16rem;
                        display: inline-block;
                    }
                    .label{
                        display: inline-block;
                        background: #999;
                        padding:3px;
                    }
                }
                .label{
                    margin: 0 .16rem .24rem;
                    // margin-top:.24rem;
                    p{
                        display: inline-block;
                        padding:2px;
                        font-size:.24rem;
                        color:#666666;
                        margin:0 .16rem .08rem 0;
                        background: #F5F5F5;
                        border-radius: 2px;
                        padding:.02rem .16rem;
                        
                    }
                }
                .minHeight{
                    min-height:.76rem;
                    line-height: .38rem;
                }
                &:last-child{
                    margin-right:.24rem;
                }
                .turnRight{
                    text-align: right;
                }
            }
            
        }
    }
    .fill{
        width:100%;
        height:.24rem;
        background: #F5F5F5;
    }
    .typeList{
        width:$defaultWidth;
        margin:20px auto 10px;
    }
}
    
</style>