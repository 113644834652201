<template>
    <div class="model" v-show="showModel" @click="cancle">
        <div class="right">
            <img src="@/assets/arrow.png" alt="">
            <div>点击右上角 … 进行分享</div>
        </div>
    </div>
</template>
<script>
export default {
    name:'model',
    props:{
        showModel:{
            type:Boolean
        }
    },
    methods:{
        cancle(){
            this.$emit('update:showModel',false)
        }
    }
}
</script>
<style lang="scss" scoped>
.model{
    width:100%;
    height:100vh;
    opacity:0.6;
    background: rgb(0,0,0);
    position: fixed;
    top:0;
    left:0;
    z-index: 99;
    .right{
        position: absolute;
        top:.42rem;
        right:.28rem;
        font-size: .26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: right;
        img{
            width:.74rem;
            margin-right:.44rem;
            // display: block;
            
        }
    }

}
    
</style>